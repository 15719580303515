.article {
    margin: 150px 0 150px 0;
    line-height: 1.6rem;

    h1 {
    font-size :2.5rem;
    text-align: center;
    margin-bottom: 100px;
    }

    h2 {
    margin-bottom: 1rem;
    margin-top: 3rem;
    font-weight: 500;
    line-height: 1.2rem;
    font-size: 1.5rem;
    }

    h3 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2rem;
    font-size: 1.1rem;
    }
}